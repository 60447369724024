/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PoweredBy from '../assets/PowLogo_SW.svg';
import Modal from 'react-modal';

import Header from './header';
import './layout.scss';
import './i18n';
import CookieBanner from './CookieBanner';

Modal.setAppElement('#___gatsby');

const Layout = ({ children, style }: { children: any; style?: any }) => {
  const { t, i18n } = useTranslation();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
        title
        }
      }
    }
  `);

  return (
    <div
      id="layout"
      style={{
        ...style,
        height: '100vh',
        width: '100vw',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        overflow: 'hidden',
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          padding: '19px 0',
          alignItems: 'center',
        }}
      >
        <a href="http://inheaden.io" target="_blank" rel="noopener noreferrer">
          <img
            src={PoweredBy}
            style={{
              height: '38px',
            }}
          />
        </a>
        <div>
          <a
            href="#"
            onClick={() =>
              i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
            }
          >
            {i18n.language === 'en' ? 'DE' : 'EN'}
          </a>{' '}
          |{' '}
          <a
            href="http://inheaden.io/privacy"
            target="_blank"
            rel="noopener noreferrer"
            title="Privacy"
          >
            {t('Privacy')}
          </a>{' '}
          |{' '}
          <a
            href="http://inheaden.io/imprint"
            target="_blank"
            rel="noopener noreferrer"
            title="Legal"
          >
            {t('Legal')}
          </a>
        </div>
      </footer>
      <CookieBanner />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
