import React, { useState } from 'react';

import './CookieBanner.scss';
import { useTranslation } from 'react-i18next';
import cookieImg from '../assets/cookie-02.svg';
import Modal from 'react-modal';

let DEFINED_ANALYTICS = false;

const CookieBanner = (p) => {
  const { t } = useTranslation();
  const key = 'COOKIE_BANNER';

  const [preference, setPreference] = useState(getState());
  const [show, setShow] = useState(!preference);
  const [values, setValues] = useState({ nonEssential: true });
  const [showModal, setShowModal] = useState(false);

  function saveState(state) {
    if (typeof window !== `undefined`) {
      localStorage.setItem(key, state);
      setPreference(state);
    }
  }

  function getState() {
    if (typeof window !== `undefined`) {
      return localStorage.getItem(key);
    }
  }

  function essentialCookies() {
    saveState('essential');
    setShow(false);
  }

  function allCookies() {
    saveState('all');
    setShow(false);

    // TODO use analytics now
  }

  if (preference === 'all') {
    let _paq = (window._paq = window._paq || []);
    if (!DEFINED_ANALYTICS) {
      DEFINED_ANALYTICS = true;
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function () {
        var u = 'https://inheaden.matomo.cloud/';
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.src = '//cdn.matomo.cloud/inheaden.matomo.cloud/matomo.js';
        s.parentNode.insertBefore(g, s);
      })();
    }
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setValues({
      ...values,
      [name]: value,
    });
  }

  function applySettings() {
    if (values.nonEssential) {
      allCookies();
    } else {
      essentialCookies();
    }
  }

  const banner = (
    <>
      <Modal
        isOpen={showModal}
        overlayClassName="modal-overlay"
        className="modal cookie-modal"
      >
        <div className="modal-header">
          <h2>{t('Cookie.Settings.Title')}</h2>
          <button
            onClick={() => {
              setShowModal(false);
            }}
          >
            &#10005;
          </button>
        </div>
        <div className="modal-content">
          <p
            style={{
              margin: '0.5rem 0',
            }}
          >
            {t('Cookie.Settings.Text')}
          </p>
          <hr />
          <div className="cookie-switches">
            <div className="switch">
              <input type="checkbox" checked disabled />
              <div>{t('Cookie.Settings.Cookies1')}</div>
            </div>
            <div className="switch">
              <input
                type="checkbox"
                checked={values.nonEssential}
                name="nonEssential"
                onChange={handleInputChange}
              />
              <div>{t('Cookie.Settings.Cookies2')}</div>
            </div>
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            marginTop: '1rem',
          }}
        >
          <button onClick={applySettings}>{t('Cookie.Settings.Save')}</button>
        </div>
      </Modal>
      <div
        className="cookie-banner"
        style={{
          zIndex: 50,
        }}
      >
        <img src={cookieImg} alt="" className="cookie-img" />
        <div className="cookie-text-container">
          <h4>{t('Cookie.Header')}</h4>
          <p>{t('Cookie.Text1')}</p>
          <a
            href="https://inheaden.io/privacy#cookies"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{t('Cookie.Text2')}</p>
          </a>
          <div className="cookie-btns">
            <button onClick={() => setShowModal(true)}>
              {t('Cookie.SettingsButton')}
            </button>
            <button onClick={allCookies}>{t('Cookie.Accept')}</button>
          </div>
        </div>
      </div>
    </>
  );

  return show ? banner : <></>;
};

export default CookieBanner;
