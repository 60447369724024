export const DE = {
  'About Inheaden': 'Über Inheaden',
  'Follow us!': 'Folgen Sie uns!',
  Connect: 'Connect',
  Create: 'Create',
  Collaborate: 'Collaborate',
  'Enter your Email Address': 'Ihre E-Mail Adresse',
  'Notify me': 'Informiere mich',
  Subtitle: 'Ein europäisches DSGVO konformes Video-Konferenz Tool',
  'Thank you': 'Vielen Dank! Wir halten Sie auf dem Laufenden!',
  Failure: 'Ups! Etwas ist schief gelaufen, bitte versuchen Sie es erneut',
  'Newsletter Disclaimer':
    'Mit der Eingabe Ihrer E-Mail Adresse akzeptieren Sie unsere Datenschutzerklärung.',
  Privacy: 'Datenschutz',
  Legal: 'Impressum',
  Cookie: {
    Header: 'Cookies!',
    Text1:
      'Wir nutzen Cookies, um Ihnen einen besseren Service zu bieten und zu lernen, wie Sie unsere Webseite nutzen.',
    Text2:
      'Wenn Sie unsere Webseite nutzen, stimmen Sie unserer Cookie Policy zu.',
    Accept: 'Alle nutzen',
    SettingsButton: 'Einstellungen',
    Settings: {
      Title: 'Cookie Einstellungen',
      Text: 'Hier können Sie einstellen, welche Cookies verwendet werden.',
      Cookies1:
        'Essentielle Cookies: Werden genutzt, um Einstellungen auf der Webseite zu speichern. (Immer aktiviert)',
      Cookies2:
        'Nicht-Essentielle Cookies: Werden genutzt für Marketing und Analyse Zwecke.',
      Save: 'Einstellungen speichern',
    },
  },
};
