import React, { useState } from 'react';
import INCO_LOGO from '../assets/CM_Borderless_CT.svg';
import instagram from '../assets/instagram.svg';
import linkedin from '../assets/linkedin.svg';
import twitter from '../assets/twitter.svg';
import menu from '../assets/menu.svg';
import cross from '../assets/cross.svg';

import '../styles.scss';
import './header.scss';
import { useTranslation } from 'react-i18next';

const Header = ({ siteTitle }) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <nav
        style={{
          padding: '16px 0',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
        }}
      >
        <div id="header-image">
          <img
            src={INCO_LOGO}
            style={{
              height: '43px',
            }}
          />
        </div>
        <div id="menu-button" style={{ display: 'none' }}>
          <button onClick={() => setShowMenu(!showMenu)}>
            <img src={showMenu ? cross : menu} />
          </button>
        </div>
        <div id="nav-menu" className={showMenu ? 'show' : ''}>
          <a
            href="http://inheaden.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('About Inheaden')}</span>
          </a>

          <a
            href="http://links.inheaden.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('Follow us!')}</span>
          </a>
          <span
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '16px',
              alignItems: 'center',
            }}
            className="social-media-container"
          >
            <a
              href="https://www.linkedin.com/company/inheaden"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} />
            </a>
            <a
              href="https://twitter.com/inheaden"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} />
            </a>
            <a
              href="https://www.instagram.com/inheaden/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} />
            </a>
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
