export const EN = {
  'About Inheaden': 'About Inheaden',
  'Follow us!': 'Follow us!',
  Connect: 'Connect',
  Create: 'Create',
  Collaborate: 'Collaborate',
  'Enter your Email Address': 'Enter your Email Address',
  'Notify me': 'Notify me',
  Subtitle: 'A Privacy-centric European Video Conferencing Tool',
  'Thank you': 'Thank you! We will keep you posted!',
  Failure: 'Oops! Something did not work here, please try again.',
  'Newsletter Disclaimer':
    'By entering your email address, you accept our privacy policy.',
  Privacy: 'Privacy',
  Legal: 'Imprint',
  Cookie: {
    Header: 'Cookies!',
    Text1:
      'We use cookies to provide you with a better service and learn about how you use our website.',
    Text2: 'By using our website, you agree to our cookie policy.',
    Accept: 'Accept all',
    SettingsButton: 'Settings',
    Settings: {
      Title: 'Cookie Settings',
      Text: 'Here you can change which cookies will be used.',
      Cookies1:
        'Essential cookies for saving website settings. (Always enabled)',
      Cookies2:
        'Non-Essential cookies: Used for analytical and marketing purposes.',
      Save: 'Save settings',
    },
  },
};
