import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { EN } from '../assets/i18n/en/en';
import { DE } from '../assets/i18n/de/de';

// the translations
const resources = {
  en: { translation: EN },
  de: { translation: DE },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    nsSeparator: '.',
    debug: true,

    keySeparator: '.', // we do use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
